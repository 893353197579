import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
})
export class AppComponent
{
    constructor()
    {
    }
}

export function sortOnProperty<T> (items: T[], property: string, isAsc: boolean = true): T[] {
    if (isAsc) {
        return items.sort((a, b) => (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0))
    } else {
        return items.sort((a, b) => (a[property] > b[property]) ? -1 : ((b[property] > a[property]) ? 1 : 0))
    }
}

export function sortOnSelf<T> (items: T[], isAsc: boolean = true): T[] {
    if (isAsc) {
        return items.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
    } else {
        return items.sort((a, b) => (a > b) ? -1 : ((b > a) ? 1 : 0))
    }
}

export function sumList(arr: number[]) {
    return arr.reduce((prev, cur) => +prev + +cur, 0); //+x is a unary function to parse number, this isn't a typo
}

export function getParentUrl(_router: Router) {
    return _router.url.split('/').slice(0, -1).join('/');
}

export function getUnique(array: any[]) {
    return array.filter((val, i, arr) => arr.indexOf(val) === i)
}

export function getUniqueOnProp(array: any[], property: string) {
    return array.filter((val, i, arr) => arr.map(x => x[property]).indexOf(val[property]) === i)
}
