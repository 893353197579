/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { OrderDirection } from '../models/order-direction';
import { PaginationRequest } from '../models/pagination-request';
import { PropertyFilter } from '../models/property-filter';
import { WageDtoBaseResponse } from '../models/wage-dto-base-response';
import { WageListDto } from '../models/wage-list-dto';
import { WageListDtoBaseResponse } from '../models/wage-list-dto-base-response';
import { WageListDtoListBaseResponse } from '../models/wage-list-dto-list-base-response';
import { WageListDtoPagedResponseBaseResponse } from '../models/wage-list-dto-paged-response-base-response';

@Injectable({
  providedIn: 'root',
})
export class WageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiWageListWagesPost
   */
  static readonly ApiWageListWagesPostPath = '/api/Wage/list-wages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageListWagesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageListWagesPost$Plain$Response(params?: {
    body?: PaginationRequest
  }): Observable<StrictHttpResponse<WageListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageListWagesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageListWagesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageListWagesPost$Plain(params?: {
    body?: PaginationRequest
  }): Observable<WageListDtoPagedResponseBaseResponse> {

    return this.apiWageListWagesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoPagedResponseBaseResponse>) => r.body as WageListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageListWagesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageListWagesPost$Json$Response(params?: {
    body?: PaginationRequest
  }): Observable<StrictHttpResponse<WageListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageListWagesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageListWagesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageListWagesPost$Json(params?: {
    body?: PaginationRequest
  }): Observable<WageListDtoPagedResponseBaseResponse> {

    return this.apiWageListWagesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoPagedResponseBaseResponse>) => r.body as WageListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiWageListWagesForDriverGet
   */
  static readonly ApiWageListWagesForDriverGetPath = '/api/Wage/list-wages-for-driver';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageListWagesForDriverGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageListWagesForDriverGet$Plain$Response(params?: {
    DriverId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<WageListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageListWagesForDriverGetPath, 'get');
    if (params) {
      rb.query('DriverId', params.DriverId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageListWagesForDriverGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageListWagesForDriverGet$Plain(params?: {
    DriverId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<WageListDtoPagedResponseBaseResponse> {

    return this.apiWageListWagesForDriverGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoPagedResponseBaseResponse>) => r.body as WageListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageListWagesForDriverGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageListWagesForDriverGet$Json$Response(params?: {
    DriverId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<WageListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageListWagesForDriverGetPath, 'get');
    if (params) {
      rb.query('DriverId', params.DriverId, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageListWagesForDriverGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageListWagesForDriverGet$Json(params?: {
    DriverId?: number;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<WageListDtoPagedResponseBaseResponse> {

    return this.apiWageListWagesForDriverGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoPagedResponseBaseResponse>) => r.body as WageListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiWageAllListWagesGet
   */
  static readonly ApiWageAllListWagesGetPath = '/api/Wage/all-list-wages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageAllListWagesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageAllListWagesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<WageListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageAllListWagesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageAllListWagesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageAllListWagesGet$Plain(params?: {
  }): Observable<WageListDtoListBaseResponse> {

    return this.apiWageAllListWagesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoListBaseResponse>) => r.body as WageListDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageAllListWagesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageAllListWagesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<WageListDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageAllListWagesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageAllListWagesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageAllListWagesGet$Json(params?: {
  }): Observable<WageListDtoListBaseResponse> {

    return this.apiWageAllListWagesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoListBaseResponse>) => r.body as WageListDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiWageWageGet
   */
  static readonly ApiWageWageGetPath = '/api/Wage/wage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageWageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageWageGet$Plain$Response(params?: {
    wageId?: number;
  }): Observable<StrictHttpResponse<WageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageWageGetPath, 'get');
    if (params) {
      rb.query('wageId', params.wageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageWageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageWageGet$Plain(params?: {
    wageId?: number;
  }): Observable<WageDtoBaseResponse> {

    return this.apiWageWageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WageDtoBaseResponse>) => r.body as WageDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageWageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageWageGet$Json$Response(params?: {
    wageId?: number;
  }): Observable<StrictHttpResponse<WageDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageWageGetPath, 'get');
    if (params) {
      rb.query('wageId', params.wageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageWageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageWageGet$Json(params?: {
    wageId?: number;
  }): Observable<WageDtoBaseResponse> {

    return this.apiWageWageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WageDtoBaseResponse>) => r.body as WageDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiWageWagePut
   */
  static readonly ApiWageWagePutPath = '/api/Wage/wage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageWagePut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageWagePut$Plain$Response(params?: {
    body?: WageListDto
  }): Observable<StrictHttpResponse<WageListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageWagePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageWagePut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageWagePut$Plain(params?: {
    body?: WageListDto
  }): Observable<WageListDtoBaseResponse> {

    return this.apiWageWagePut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoBaseResponse>) => r.body as WageListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageWagePut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageWagePut$Json$Response(params?: {
    body?: WageListDto
  }): Observable<StrictHttpResponse<WageListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageWagePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageWagePut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageWagePut$Json(params?: {
    body?: WageListDto
  }): Observable<WageListDtoBaseResponse> {

    return this.apiWageWagePut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoBaseResponse>) => r.body as WageListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiWageWagePost
   */
  static readonly ApiWageWagePostPath = '/api/Wage/wage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageWagePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageWagePost$Plain$Response(params?: {
    overwrite?: boolean;
    body?: WageListDto
  }): Observable<StrictHttpResponse<WageListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageWagePostPath, 'post');
    if (params) {
      rb.query('overwrite', params.overwrite, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageWagePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageWagePost$Plain(params?: {
    overwrite?: boolean;
    body?: WageListDto
  }): Observable<WageListDtoBaseResponse> {

    return this.apiWageWagePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoBaseResponse>) => r.body as WageListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageWagePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageWagePost$Json$Response(params?: {
    overwrite?: boolean;
    body?: WageListDto
  }): Observable<StrictHttpResponse<WageListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageWagePostPath, 'post');
    if (params) {
      rb.query('overwrite', params.overwrite, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageWagePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageWagePost$Json(params?: {
    overwrite?: boolean;
    body?: WageListDto
  }): Observable<WageListDtoBaseResponse> {

    return this.apiWageWagePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoBaseResponse>) => r.body as WageListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiWageWageListItemGet
   */
  static readonly ApiWageWageListItemGetPath = '/api/Wage/wage-list-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageWageListItemGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageWageListItemGet$Plain$Response(params?: {
    wageId?: number;
  }): Observable<StrictHttpResponse<WageListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageWageListItemGetPath, 'get');
    if (params) {
      rb.query('wageId', params.wageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageWageListItemGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageWageListItemGet$Plain(params?: {
    wageId?: number;
  }): Observable<WageListDtoBaseResponse> {

    return this.apiWageWageListItemGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoBaseResponse>) => r.body as WageListDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageWageListItemGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageWageListItemGet$Json$Response(params?: {
    wageId?: number;
  }): Observable<StrictHttpResponse<WageListDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageWageListItemGetPath, 'get');
    if (params) {
      rb.query('wageId', params.wageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WageListDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageWageListItemGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageWageListItemGet$Json(params?: {
    wageId?: number;
  }): Observable<WageListDtoBaseResponse> {

    return this.apiWageWageListItemGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<WageListDtoBaseResponse>) => r.body as WageListDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiWageUpdateExistingForJobPost
   */
  static readonly ApiWageUpdateExistingForJobPostPath = '/api/Wage/update-existing-for-job';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageUpdateExistingForJobPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageUpdateExistingForJobPost$Plain$Response(params?: {
    body?: WageListDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageUpdateExistingForJobPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageUpdateExistingForJobPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageUpdateExistingForJobPost$Plain(params?: {
    body?: WageListDto
  }): Observable<BooleanBaseResponse> {

    return this.apiWageUpdateExistingForJobPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageUpdateExistingForJobPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageUpdateExistingForJobPost$Json$Response(params?: {
    body?: WageListDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageUpdateExistingForJobPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageUpdateExistingForJobPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiWageUpdateExistingForJobPost$Json(params?: {
    body?: WageListDto
  }): Observable<BooleanBaseResponse> {

    return this.apiWageUpdateExistingForJobPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiWageToggleArchiveWageDelete
   */
  static readonly ApiWageToggleArchiveWageDeletePath = '/api/Wage/toggle-archive-wage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageToggleArchiveWageDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageToggleArchiveWageDelete$Plain$Response(params?: {
    wageId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageToggleArchiveWageDeletePath, 'delete');
    if (params) {
      rb.query('wageId', params.wageId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageToggleArchiveWageDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageToggleArchiveWageDelete$Plain(params?: {
    wageId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiWageToggleArchiveWageDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWageToggleArchiveWageDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageToggleArchiveWageDelete$Json$Response(params?: {
    wageId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WageService.ApiWageToggleArchiveWageDeletePath, 'delete');
    if (params) {
      rb.query('wageId', params.wageId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiWageToggleArchiveWageDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWageToggleArchiveWageDelete$Json(params?: {
    wageId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiWageToggleArchiveWageDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

}
